import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'
import ExtraQueryStringParseService from '../services/extra_query_string_parse_service.js'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'product_tags',
  attributes: [
    'id',
    'name',
    'created_at',
    'updated_at',
    'position',
    'is_age_restricted',
    'is_available',
    'is_health_care'
  ],
  editableAttributes: [
    'name',
    'is_age_restricted',
    'is_available',
    'is_health_care'
  ]
}

export default class ProductTag extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  fetchProducts(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/products?${FetchingDataOptionsService.call(
        options
      )}${ExtraQueryStringParseService.call(options)}`
    )
  }

  bulkAssignProducts(productIds) {
    return axios.put(`${this.apiBasePath()}/${this.id}/products/bulk_assign`, {
      product_ids: productIds
    })
  }

  bulkMoveProducts(data) {
    return axios.put(`${this.apiBasePath()}/${this.id}/products/bulk_move`, {
      target_tag_id: data.targetTag.id,
      product_ids: data.productIds
    })
  }

  bulkRemoveProducts(productIds) {
    return axios.put(`${this.apiBasePath()}/${this.id}/products/bulk_remove`, {
      product_ids: productIds
    })
  }

  removeProduct(product) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/products/${product.id}/remove`
    )
  }

  toggleAvailability() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_availability`)
  }

  // extra methods or helpers here...
}
