import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'category_system_categories',
  attributes: [
    'id',
    'name',
    'name_with_parents',
    'description',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'cover',
    'category_system_id',
    'parent_id',
    'is_default',
    'default_usage',
    'lft',
    'rgt',
    'depth',
    'created_at',
    'updated_at',
    'position',
    'top_resource_ids',
    'is_health_care'
  ],
  editableAttributes: [
    'name',
    'description',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'category_system_id',
    'parent_id',
    'top_resource_ids',
    'is_health_care'
  ]
}

export default class Category extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static byType(options = {}) {
    const additionalParams = ['type', 'level']
    let queryString = FetchingDataOptionsService.call(options)

    additionalParams.forEach((param) => {
      if (options[param]) queryString += `&${param}=${options[param]}`
    })

    return axios.get(`${new this().apiBasePath()}/by_type?${queryString}`)
  }

  static byUsage(options = {}) {
    return axios.get(
      `${new this().apiBasePath()}/by_usage?${FetchingDataOptionsService.call(
        options
      )}&usage=${options.usage}`
    )
  }

  fetchContents(options = {}) {
    const subCategoriesIncludedParams = options.sub_categories_included
      ? '&sub_categories_included=1'
      : ''

    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/contents?${FetchingDataOptionsService.call(
        options
      )}${subCategoriesIncludedParams}`
    )
  }

  fetchSalesEvents(options = {}) {
    const subCategoriesIncludedParams = options.sub_categories_included
      ? '&sub_categories_included=1'
      : ''

    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/sales_events?${FetchingDataOptionsService.call(
        options
      )}${subCategoriesIncludedParams}`
    )
  }

  fetchPromotionalArticles(options = {}) {
    const FetchingDataOptionParams = FetchingDataOptionsService.call(options)
    const subCategoriesIncludedParams = options.sub_categories_included
      ? 'sub_categories_included=1'
      : null
    const skipTopOrderParams = options.skipTopOrder ? 'skip_top_order=1' : null

    const queryString = [
      FetchingDataOptionParams,
      subCategoriesIncludedParams,
      skipTopOrderParams
    ]
      .filter((string) => string)
      .join('&')
    return axios.get(
      `${this.apiBasePath()}/${this.id}/promotional_articles?${queryString}`
    )
  }

  // extra methods or helpers here...
  is_root() {
    return this.parent_id === null
  }

  is_leaf() {
    return this.rgt - this.lft === 1
  }
}
