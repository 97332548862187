import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'logistic_companies',
  attributes: [
    'id',
    'name',
    'code',
    'tracking_url',
    'created_at',
    'updated_at'
  ],
  editableAttributes: ['name', 'code', 'tracking_url']
}

export default class LogisticCompany extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  displayName() {
    return `${this.code} - ${this.name}`
  }

  // extra methods or helpers here...
}
