import * as types from './mutation-types'
import OrderPayment from '../../resource_models/order_payment'

// export const all = ({
//   dispatch,
//   commit
// }, options) => {
//   commit(types.API_REQUEST_START, 'all')

//   return new Promise((resolve, reject) => {
//     OrderPayment.all(options)
//       .then(response => {
//         commit(types.FETCH_ORDER_PAYMENTS_SUCCESS, response)

//         resolve(response)
//       })
//       .catch(errors => {
//         commit(types.API_REQUEST_FAIL, errors)
//         dispatch('errorMessageHandler', {
//           errors,
//           retryAction: all,
//           ref: {
//             dispatch,
//             commit
//           },
//           params: options
//         }, {
//           root: true
//         })

//         reject(errors)
//       })
//   })
// }

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    OrderPayment.find(id)
      .then((response) => {
        commit(types.GET_ORDER_PAYMENT_SUCCESS, response)
        dispatch('orders/receiveInvoicesFormRelationship', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

// export const save = ({
//   dispatch,
//   commit
// }, model) => {
//   commit(types.API_REQUEST_START, 'save')

//   return new Promise((resolve, reject) => {
//     model.save()
//       .then(response => {
//         if (model.isNewRecord()) {
//           commit(types.ADD_ORDER_PAYMENT_SUCCESS, response)
//         } else {
//           commit(types.UPDATE_ORDER_PAYMENT_SUCCESS, response)
//         }

//         resolve(response)
//       })
//       .catch(errors => {
//         model.errors.record(errors)
//         commit(types.API_REQUEST_FAIL, errors)
//         dispatch('errorMessageHandler', {
//           errors,
//           retryAction: save,
//           ref: {
//             dispatch,
//             commit
//           },
//           params: model
//         }, {
//           root: true
//         })

//         reject(errors)
//       })
//   })
// }

// export const destroy = ({
//   dispatch,
//   commit
// }, model) => {
//   commit(types.API_REQUEST_START, 'destroy')

//   return new Promise((resolve, reject) => {
//     model.destroy()
//       .then(response => {
//         commit(types.DELETE_ORDER_PAYMENT_SUCCESS, model.id)

//         resolve(response)
//       })
//       .catch(errors => {
//         model.errors.record(errors)
//         commit(types.API_REQUEST_FAIL, errors)
//         dispatch('errorMessageHandler', {
//           errors,
//           retryAction: destroy,
//           ref: {
//             dispatch,
//             commit
//           },
//           params: model
//         }, {
//           root: true
//         })

//         reject(errors)
//       })
//   })
// }

export const processOnsitePayment = ({ dispatch, commit }, requestBody) => {
  commit(types.API_REQUEST_START, 'processOnsitePayment')

  return new Promise((resolve, reject) => {
    OrderPayment.processOnsitePayment(requestBody)
      .then((response) => {
        commit(types.PROCESS_ONSITE_PAYMENT_SUCCESS, response)
        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: processOnsitePayment,
            ref: {
              dispatch,
              commit
            },
            params: requestBody
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const payByCash = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'payByCash')

  return new Promise((resolve, reject) => {
    model
      .payByCash()
      .then((response) => {
        commit(types.UPDATE_ORDER_PAYMENT_SUCCESS, response)
        dispatch('orders/updateResourceFromRelationship', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: payByCash,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateRefundInfo = ({ dispatch, commit }, requestBody) => {
  commit(types.API_REQUEST_START, 'updateRefundInfo')

  return new Promise((resolve, reject) => {
    OrderPayment.updateRefundInfo(requestBody)
      .then((response) => {
        if (requestBody.data.attributes.id)
          commit(types.UPDATE_ORDER_PAYMENT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateRefundInfo,
            ref: {
              dispatch,
              commit
            },
            params: requestBody
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const confirmRefund = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'confirmRefund')

  return new Promise((resolve, reject) => {
    model
      .confirmRefund()
      .then((response) => {
        commit(types.UPDATE_ORDER_PAYMENT_SUCCESS, response)
        dispatch('orders/updateResourceFromRelationship', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: confirmRefund,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const issueInvoice = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'issueInvoice')

  return new Promise((resolve, reject) => {
    model
      .issueInvoice()
      .then((response) => {
        commit(types.UPDATE_ORDER_PAYMENT_SUCCESS, response)
        dispatch('orders/updateResourceFromRelationship', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: issueInvoice,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const queryInvoiceInfo = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'queryInvoiceInfo')

  return new Promise((resolve, reject) => {
    model
      .queryInvoiceInfo()
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'queryInvoiceInfo')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: queryInvoiceInfo,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const syncInvoiceInfo = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'syncInvoiceInfo')

  return new Promise((resolve, reject) => {
    model
      .syncInvoiceInfo()
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'syncInvoiceInfo')
        dispatch('orders/getResourceFromRelationship', response, {
          root: true
        })
        dispatch('orderShipments/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch('orderPayments/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch('orderItems/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: syncInvoiceInfo,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const queryTradeInfo = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'queryTradeInfo')

  return new Promise((resolve, reject) => {
    model
      .queryTradeInfo()
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'queryTradeInfo')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: queryTradeInfo,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const syncTradeInfo = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'syncTradeInfo')

  return new Promise((resolve, reject) => {
    model
      .syncTradeInfo()
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'syncTradeInfo')
        dispatch('orders/getResourceFromRelationship', response, {
          root: true
        })
        dispatch('orderShipments/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch('orderPayments/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch('orderItems/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: syncTradeInfo,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const adminNotes = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'notes')

  return new Promise((resolve, reject) => {
    model
      .adminNotes()
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'syncTradeInfo')
        dispatch('orderPayments/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: syncTradeInfo,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const existingMethodNames = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'existingMethodNames')

  return new Promise((resolve, reject) => {
    OrderPayment.existingMethodNames()
      .then((response) => {
        commit(types.GET_ORDER_PAYMENT_EXISTING_METHOD_NAMES_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: existingMethodNames,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_ORDER_PAYMENTS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_ORDER_PAYMENT_SUCCESS, response)

    resolve(response)
  })
}
