export const FETCH_LOGISTIC_COMPANIES_SUCCESS =
  'FETCH_LOGISTIC_COMPANIES_SUCCESS'
export const GET_RELATED_LOGISTIC_COMPANIES_SUCCESS =
  'GET_RELATED_LOGISTIC_COMPANIES_SUCCESS'
export const GET_LOGISTIC_COMPANY_SUCCESS = 'GET_LOGISTIC_COMPANY_SUCCESS'
export const ADD_LOGISTIC_COMPANY_SUCCESS = 'ADD_LOGISTIC_COMPANY_SUCCESS'
export const UPDATE_LOGISTIC_COMPANY_SUCCESS = 'UPDATE_LOGISTIC_COMPANY_SUCCESS'
export const DELETE_LOGISTIC_COMPANY_SUCCESS = 'DELETE_LOGISTIC_COMPANY_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
