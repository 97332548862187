import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'
import { useI18n } from 'skid-composables'
import moment from 'moment'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'banners',
  attributes: [
    'id',
    'image',
    'mobile_image',
    'alt_text',
    'use_type',
    'position',
    'url',
    'is_health_care',
    'is_available',
    'started_at',
    'ended_at',
    'is_live'
  ],
  editableAttributes: [
    'alt_text',
    'url',
    'is_health_care',
    'started_at',
    'ended_at'
  ]
}

export default class Banner extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static useTypes() {
    return axios.get(`${new this().apiBasePath()}/use_type`)
  }

  static allByUseType(options) {
    return axios.get(
      `${new this().apiBasePath()}?${FetchingDataOptionsService.call(options)}`
    )
  }

  displayPeriod(options = {}) {
    const i18n = useI18n()

    const defaults = {
      format: 'YYYY MMMDD HH:mm:ss',
      multipleLine: false
    }

    options = Object.assign({}, defaults, options)

    if (!this.started_at && !this.ended_at)
      return i18n.copyLocaleText('no_limit')

    if (this.started_at && !this.ended_at)
      return i18n.copyLocaleText('after_time', {
        time: moment.unix(this.started_at).format(options.format)
      })

    if (!this.started_at && this.ended_at)
      return i18n.copyLocaleText('before_time', {
        time: moment.unix(this.ended_at).format(options.format)
      })

    return `${moment
      .unix(this.started_at)
      .format(options.format)} ~ <br>${moment
      .unix(this.ended_at)
      .format(options.format)}`
  }

  toggleAvailability() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_availability`)
  }

  /**
   * Banner 使用 form data 傳遞資料，因此重新定義 `#save`
   *
   * @param {FormData} formData 內容包含 `banner[image]`, `banner[alt_text]`
   * @returns {Promise} 回傳 response 或 errors
   */
  save(formData) {
    if (this.isNewRecord()) {
      return axios.post(this.apiBasePath(), formData)
    }
    return axios.put(`${this.apiBasePath()}/${this.id}`, formData)
  }
  // extra methods or helpers here...
}
