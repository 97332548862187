import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'order_payments',
  attributes: [
    'id',
    'payment_method',
    'order',
    'amount',
    'state',
    'merchant_order_no',
    'latest_notification',
    'gateway_info',
    'refund_bank_code',
    'refund_account',
    'refund_recipient',
    'invoice',
    'invoices',
    'refunds',
    'admin_notes'
  ],
  editableAttributes: ['notes', 'admin_notes']
}

export default class OrderPayment extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static updateRefundInfo(requestBody) {
    return axios.put(`${new this().apiBasePath()}/refund_info`, requestBody)
  }

  payByCash() {
    const requestBody = {
      data: {
        type: 'order_payments',
        attributes: {
          notes: this.notes
        }
      }
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/cash_payment`,
      requestBody
    )
  }

  confirmRefund() {
    return axios.put(`${this.apiBasePath()}/${this.id}/confirm_refund`)
  }

  queryTradeInfo() {
    return axios.get(`${this.apiBasePath()}/${this.id}/query_trade_info`)
  }

  syncTradeInfo() {
    return axios.post(`${this.apiBasePath()}/${this.id}/sync_trade_info`)
  }

  issueInvoice() {
    return axios.post(`${this.apiBasePath()}/${this.id}/issue_invoice`)
  }

  queryInvoiceInfo() {
    return axios.get(`${this.apiBasePath()}/${this.id}/query_invoice_info`)
  }

  syncInvoiceInfo() {
    return axios.post(`${this.apiBasePath()}/${this.id}/sync_invoice_info`)
  }

  static processOnsitePayment(requestBody) {
    return axios.post(
      `${new this().apiBasePath()}/process_onsite_payment`,
      requestBody
    )
  }

  static existingMethodNames() {
    return axios.get(`${new this().apiBasePath()}/existing_method_names`)
  }

  adminNotes() {
    const requestBody = {
      data: {
        type: 'order_payments',
        attributes: {
          admin_notes: this.admin_notes
        }
      }
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/notes`, requestBody)
  }

  // extra methods or helpers here...

  stateType() {
    return {
      'is-default': this.state === 'checkout',
      'is-info': this.state === 'processing',
      'is-warning': ['pending', 'waiting_info'].includes(this.state),
      'is-error': this.state === 'failed',
      'is-success': this.state === 'completed',
      'is-dark': this.state === 'void'
    }
  }

  isPayable() {
    return (
      ['checkout', 'failed'].includes(this.state) ||
      this.cashOnDeliveryPayable()
    )
  }

  cashOnDeliveryPayable() {
    return (
      this.state === 'pending' &&
      this.gateway_info.provider === 'cash_on_delivery'
    )
  }

  hasRefundInfo() {
    return (
      this.refund_bank_code !== null &&
      this.refund_account !== null &&
      this.refund_recipient !== null
    )
  }

  invoiceState() {
    if (this.invoice) {
      return this.invoice.aasm_state
    } else {
      return 'null'
    }
  }
}
