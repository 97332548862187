import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'contents',
  attributes: [
    'id',
    'title',
    'content_category_id',
    'published_at',
    'description',
    'content',
    'cover',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'category_name',
    'category',
    'slug',
    'is_default',
    'created_at',
    'updated_at',
    'is_health_care'
  ],
  editableAttributes: [
    'title',
    'published_at',
    'description',
    'content',
    'cover',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'category_id',
    'slug',
    'uploaded_attachment_ids',
    'is_health_care'
  ]
}

export default class Content extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static all(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    if (options.usage) queryString += `&usage=${options.usage}`
    if (options.by_default_usages)
      options.by_default_usages.map((usage) => {
        queryString += `&by_default_usages[]=${usage}`
      })

    return axios.get(`${new this().apiBasePath()}?${queryString}`)
  }

  updateCover(formData) {
    return axios.put(`${this.apiBasePath()}/${this.id}/cover`, formData)
  }

  removeCover() {
    return axios.delete(`${this.apiBasePath()}/${this.id}/cover`)
  }
  // extra methods or helpers here...
  hasCover() {
    return this.cover && this.cover.url !== null
  }

  coverImageThumb() {
    return this.cover.thumb.url
  }

  cardContent() {
    if (!this.content) return ''
    return this.content.replace(/<[^>]*>?/gm, '').substring(0, 50)
  }
}
