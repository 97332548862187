import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'member_levels',
  attributes: [
    'id',
    'title',
    'preferences',
    'merits_list',
    'users_count',
    'level_code'
  ],
  editableAttributes: ['title', 'preferences', 'merits_list', 'level_code']
}

export default class MemberLevel extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static fetchAvailableOptions() {
    return axios.get(`${new this().apiBasePath()}/available_options`)
  }

  // extra methods or helpers here...
}
